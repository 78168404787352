import { getShortTimeZoneFromDateString } from "../../../../../../../../utils/timeUtils";
 
export default {
    ADD_REMINDER_TITLE: "Add reminder",
    ADD_REMINDER_CANCEL_TEXT: "Cancel",
    ADD_REMINDER_OK_TEXT: "Save reminder",
    DATE_FORMAT: "dddd, MMMM Do YYYY",
    TIME_FORMAT: `h:mm a [${getShortTimeZoneFromDateString(new Date().toString())}]`,
    NAME_INPUT_BOX_PLACEHOLDER: "Enter name",
    EMAIL_INPUT_BOX_PLACEHOLDER: "Enter email",
    EMAIL_SUBJECT_INPUT_BOX_PLACEHOLDER: "Enter email subject",
    EMAIL_HEADER_INPUT_BOX_PLACEHOLDER: "Enter email header",
    EMAIL_BODY_INPUT_BOX_PLACEHOLDER: "Enter email body",
    ADD_REMINDER_MODAL_VISIBLE: { addReminderModalVisible: false },
    REMINDER_DEFAULT_EMAIL : "surveys@culturebie.com",
    REMINDER_DEFAULT_NAME: "Culturebie Surveys",
    REMINDER_MASCO_NAME:"Masco Talent COE",
    REMINDER_MASCO_EMAIL:"MascoTalentCOE@mascohq.com",
    REMINDER_MASCO_180_EMAIL: "LeadershipDev@mascohq.com",
    REMINDER_MASCO_180_NAME: "Masco Leadership Development",
    REMINDER_DEFAULT_SUBJECT: "Survey Expiring Soon",
    FEEDBACK_360_REMINDER_SUBJECT: "Reminder: Leadership 360 Feedback survey for {{LeaderName}} is due soon",
    FEEDBACK_180_REMINDER_SUBJECT: "Reminder: 180 Development Tool – Input for {{LeaderName}} is due soon",
    REMINDER_DEFAULT_EMAIL_HEADER: "Your feedback is important!",

    REMINDER_DEFAULT_EMAIL_HEADER_180: "Your perspective is important!",


    REMINDER_FEEDBACK_EMAIL_BODY: 'Hello {{NAME}},\n\n This is a gentle reminder to complete the Leadership 360 survey request for {{LeaderName}}. We ask you to complete this survey by {{DATE}}.\n\n{{Link}} \n\n This survey is administered by Culturebie, a third-party, and all survey responses are entirely confidential. If you have any technical issues, please email surveys@culturebie.com, and include the name of your organization.\n \nThank you,\nCulturebie 360 Feedback Administration Team',
    REMINDER_FEEDBACK_EMAIL_BODY_NO_DATE: "Hello {{NAME}},\n\nThis is a gentle reminder to complete the Leadership 360 survey request for {{LeaderName}}.\n\n{{Link}}\n\nAll survey responses are entirely confidential. This survey is administered by Culturebie, a third-party, and individual results will not be shared with your work organization.\n\nIf you have any technical issues, please email surveys@culturebie.com, and include the name of your organization.\n\nThank you,\nCulturebie 360 Feedback Administration Team",
    REMINDER_FEEDBACK_EMAIL_BODY_180: 'Hello {{NAME}},\n\nThis is a brief reminder to share your perspectives via the 180 Development tool for {{LeaderName}}. We ask you to complete it by {{DATE}}.\n\n{{Link}} \n\n This survey is administered by Culturebie, a third-party, and individual results are kept private and secure. If you have any technical issues, please email surveys@culturebie.com, and include the name of your organization.\n \nThank you,\nCulturebie Team',
    REMINDER_FEEDBACK_EMAIL_BODY_NO_DATE_180: 'Hello {{NAME}},\n\nThis is a brief reminder to share your perspectives via the 180 Development tool for {{LeaderName}}.\n\n{{Link}} \n\n This survey is administered by Culturebie, a third-party, and individual results are kept private and secure. If you have any technical issues, please email surveys@culturebie.com, and include the name of your organization.\n \nThank you,\nCulturebie Team',
    DEFAULT_REMINDER_BODY: 'Hi {{NAME}},\n\nThis is a reminder that we really want to hear from you soon. The survey is open and awaiting your response...\n\n{{Link}}\n\nAll survey responses are entirely confidential. This survey is administered by Culturebie, a third-party, and individual results will not be shared with your work organization.\n\nIf you have any technical issues, please email surveys@culturebie.com, and include the name of your organization.\n\nThank you.',
    REMINDER_TIME_PLACEHOLDER : "Select time",
    REMINDER_DATE_PLACEHOLDER : "Select date",
    PROJECT_NAME : "{{projectName}}",
    DATE : "{{Link}}",
    NAME: "{{NAME}}",
    ERROR_MESSAGE: "Please include {{Link}} and {{NAME}} in email body",
    ERROR_MESSAGE_FOR_PROJECTNAME : "Please include {{projectName}} in email body",
    ERROR_MESSAGE_FOR_DATE : "Please include {{Link}} in email body",
    ERROR_MESSAGE_FOR_NAME: "Please include {{NAME}} in email body",

    REMINDER_FEEDBACK_EMAIL_BODY_APPLIED_360: 'Hello!\n\n This is a gentle reminder to provide your feedback for {{LeaderName}}. You are receiving this email because you were nominated by {{LeaderName}} to participate in their 360 Feedback Survey. We request you to share your feedback by {{Date}}. Please follow the link below to get started:\n\n{{Link}}\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\nThe 360 Feedback report for the Management Excellence program participants will include feedback from the direct manager. It will also include feedback from direct reports and peers which will be anonymous (feedback tool facilitated by a third-party). To protect anonymity of the individual responses, the direct reports results will be aggregated and will be made available if at least three direct reports complete the questionnaire. Similarly, individual responses of the peers will be aggregated to protect anonymity and their feedback will only be made available if at least three peers complete the questionnaire. Applied personnel will not have access to the individual data.\n\nThank you,\nCulturebie 360 Feedback Team',


    REMINDER_FEEDBACK_EMAIL_BODY_APPLIED_360_NO_DATE: 'Hello!\n\n This is a gentle reminder to provide your feedback for {{LeaderName}}. You are receiving this email because you were nominated by {{LeaderName}} to participate in their 360 Feedback Survey. Please follow the link below to get started:\n\n{{Link}}\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\nThe 360 Feedback report for the Management Excellence program participants will include feedback from the direct manager. It will also include feedback from direct reports and peers which will be anonymous (feedback tool facilitated by a third-party). To protect anonymity of the individual responses, the direct reports results will be aggregated and will be made available if at least three direct reports complete the questionnaire. Similarly, individual responses of the peers will be aggregated to protect anonymity and their feedback will only be made available if at least three peers complete the questionnaire. Applied personnel will not have access to the individual data.\n\nThank you,\nCulturebie 360 Feedback Team\n\nThank you,\nCulturebie 360 Feedback Team',
    APPLIED_FEEDBACK_360_SUBJECT: "Reminder | 360 Feedback - Please share your feedback for {{LeaderName}}",
    APPLIED_FEEDBACK_360_HEADER: "Applied 360 Feedback",
    SELF_COMPLETED_EMAIL_TEMPLATE: {
        emailHeader: "Survey Completion Status",
        emailSubject: "Survey Status",
        emailTemplate: 'Dear {{Name}}, \n\n The most recent completion status for your 360 Feedback is shared in the table below by peer group. For confidentiality reasons, a minimum of 3 nominations are required to show the number of responses received for a rater category. \n\nIf you have any questions please reach out to support@culturebie.com.\n\nThank you,\nCulturebie 360 Feedback Team'
    },
    SELF_REMINDER_MAIL_APPLIED_BODY: "Hello {{Name}}!\n\nThis is a gentle reminder to provide your self-feedback by {{Date}}. Please follow the link below to get started:\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\n CONFIDENTIALITY NOTE\n\nThe 360 Feedback report for the Management Excellence program participants will include feedback from the direct manager. It will also include feedback from direct reports and peers which will be anonymous (feedback tool facilitated by a third-party). To protect anonymity of the individual responses, the direct reports results will be aggregated and will be made available if at least three direct reports complete the questionnaire. Similarly, individual responses of the peers will be aggregated to protect anonymity and their feedback will only be made available if at least three peers complete the questionnaire. Applied personnel will not have access to the individual data.",
    SELF_REMINDER_MAIL_APPLIED_BODY_NO_DATE: "Hello {{Name}}!\n\nThis is a gentle reminder to provide your self-feedback. Please follow the link below to get started:\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\n CONFIDENTIALITY NOTE\n\nThe 360 Feedback report for the Management Excellence program participants will include feedback from the direct manager. It will also include feedback from direct reports and peers which will be anonymous (feedback tool facilitated by a third-party). To protect anonymity of the individual responses, the direct reports results will be aggregated and will be made available if at least three direct reports complete the questionnaire. Similarly, individual responses of the peers will be aggregated to protect anonymity and their feedback will only be made available if at least three peers complete the questionnaire. Applied personnel will not have access to the individual data.",

    SELF_REMINDER_MAIL_APPLIED_SUBJECT: "Reminder | 360 Feedback - Please complete your self-feedback",

    SELF_REMINDER_APPLIED_DIRECTOR: "Hi {{NAME}},\n\nThis is a gentle reminder for you to complete the self-feedback as part of your participation in the Leadership Insights II, a 360 Feedback process to capture feedback from various stakeholders including your self, team members, peers, and others. We request you to share your feedback by {{DATE}}. Please follow the link below to get started:\n\n{{Link}}\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\n1. The 360 Feedback is an online confidential survey-based process facilitated by us, a third party.\n2. To protect confidentiality, at least three team members must provide feedback for you to receive the results. The individual responses will be aggregated to provide meaningful insights. Similarly, individual responses of peers and other rater groups will be aggregated to protect confidentiality and their feedback will only be made available if at least three respondents submit the feedback in each rater category. Feedback from the manager(s) is non-anonymous and will be visible to the participant.\n3. Applied personnel will not have access to the individual level data.\n\nThank you,\nCulturebie 360 Feedback Team",
    SELF_REMINDER_APPLIED_DIRECTOR_NO_DATE: "Hi {{NAME}},\n\nThis is a gentle reminder for you to complete the self-feedback as part of your participation in the Leadership Insights II, a 360 Feedback process to capture feedback from various stakeholders including your self, team members, peers, and others. Please follow the link below to get started:\n\n{{Link}}\n\nThis survey is administered by Culturebie, a third-party. If you have any technical issues, please email support@culturebie.com. If you have any Privacy related questions, please write to privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\n1. The 360 Feedback is an online confidential survey-based process facilitated by us, a third party.\n2. To protect confidentiality, at least three team members must provide feedback for you to receive the results. The individual responses will be aggregated to provide meaningful insights. Similarly, individual responses of peers and other rater groups will be aggregated to protect confidentiality and their feedback will only be made available if at least three respondents submit the feedback in each rater category. Feedback from the manager(s) is non-anonymous and will be visible to the participant.\n3. Applied personnel will not have access to the individual level data.\n\nThank you,\nCulturebie 360 Feedback Team",


    REMINDER_FEEDBACK_EMAIL_BODY_APPLIED_360_OTHER: 'Hello!\n\nThis is a gentle reminder to provide your feedback for {{LeaderName}} who is participating in Applied’s 360 Feedback, a process that captures feedback from a variety of perspectives including the manager(s), team members, peers, and other stakeholders.\n\nYou are receiving this email because you were nominated by {{LeaderName}} to participate in their 360 Feedback. We request you to share your feedback by {{DATE}}. Please follow the link below to get started:\n\n{{Link}}\n\nIf you have any questions, please reach out to us at support@culturebie.com. For privacy related questions please refer to our privacy policy and/or write to us at privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\n1. The 360 Feedback Tool is an online confidential survey facilitated by us, a third party.\n2. To protect confidentiality, at least three team members must complete the questionnaire for {{LeaderName}} to receive the results. The individual responses will be aggregated to provide meaningful insights. Similarly, individual responses of peers and other rater groups will be aggregated to protect confidentiality and their feedback will only be made available if at least three respondents submit the feedback in each rater category. Feedback from the manager(s) is non-anonymous and will be visible to the participant.\n3. Applied personnel will not have access to the individual level data.\nThank you,\nCulturebie 360 Feedback Team',

    REMINDER_FEEDBACK_EMAIL_BODY_APPLIED_360_OTHER_NO_DATE: 'Hello!\n\nThis is a gentle reminder to provide your feedback for {{LeaderName}} who is participating in Applied’s 360 Feedback, a process that captures feedback from a variety of perspectives including the manager(s), team members, peers, and other stakeholders.\n\nYou are receiving this email because you were nominated by {{LeaderName}} to participate in their 360 Feedback. Please follow the link below to get started:\n\n{{Link}}\n\nIf you have any questions, please reach out to us at support@culturebie.com. For privacy related questions please refer to our privacy policy and/or write to us at privacy@culturebie.com.\n\nCONFIDENTIALITY NOTE\n\n1. The 360 Feedback Tool is an online confidential survey facilitated by us, a third party.\n2. To protect confidentiality, at least three team members must complete the questionnaire for {{LeaderName}} to receive the results. The individual responses will be aggregated to provide meaningful insights. Similarly, individual responses of peers and other rater groups will be aggregated to protect confidentiality and their feedback will only be made available if at least three respondents submit the feedback in each rater category. Feedback from the manager(s) is non-anonymous and will be visible to the participant.\n3. Applied personnel will not have access to the individual level data.\nThank you,\nCulturebie 360 Feedback Team',

}